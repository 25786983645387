import * as React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout'
import {postDate, postBody, navLinkText, listItemTags} from '../../components/layout.module.css'

function BlogPost({ data }) {
  const blog = data.mdx

  return (
    <Layout pageTitle={blog.frontmatter.title}>
      <header>
        <div className={postDate}>updated at {new Date(String(blog.parent.modifiedTime)).toLocaleString("en-US")}</div>
      </header>
      <main>
        <MDXRenderer className={postBody}>{blog.body}</MDXRenderer>
      </main>
      <footer>
        <Link className={navLinkText} to="/">←</Link>
      </footer>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($id: String!) {
    mdx(id: {eq: $id}) {
      parent {
        ... on File {
          modifiedTime
        }
      }
      body
      frontmatter {
        title
        date
    }
    }
  }
`
